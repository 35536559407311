import { cva } from "class-variance-authority";

export const logoClassName = cva(
	[
		"pointer-events-none",
		"h-full",
		"transition-all",
		"group-hover/technology-card:scale-125",
	],
	{
		variants: {
			type: {
				image: ["object-contain"],
				svg: [],
			},
			theme: {
				light: ["dark:hidden"],
				dark: ["hidden", "dark:block"],
			},
		},
	},
);
