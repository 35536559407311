import { ZodTypeAny } from "zod";
import { fromZodError, FromZodErrorOptions } from "zod-validation-error";

export const validateZodSchema =
	(schema: ZodTypeAny, options?: FromZodErrorOptions) => (value: unknown) => {
		const parseValue = schema.safeParse(value);
		if (parseValue.success) {
			return true;
		}
		const errorMessage = fromZodError(parseValue.error, {
			prefix: null,
			...options,
		}).toString();
		return errorMessage;
	};
