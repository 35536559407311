"use client";

import { CardBody, Card } from "@nextui-org/card";
import { FC, MouseEventHandler, useRef, useState } from "react";
import { Link } from "@nextui-org/link";
import { TechnologyLogo, Logo } from "./TechnologyLogo";

export type Technology = {
	title: string;
	href: string;
	logo: Logo | { light: Logo; dark: Logo };
};

type Props = {
	technology: Technology;
};

export const TechnologyCard: FC<Props> = ({ technology }) => {
	const cursorRef = useRef<HTMLSpanElement>(null);

	const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
		if (!cursorRef.current) return;

		const rect = e.currentTarget.getBoundingClientRect();

		const cursorX = e.clientX - rect.left;
		const cursorY = e.clientY - rect.top;

		const elWidth = cursorRef.current.offsetWidth;
		const elHeight = cursorRef.current.offsetHeight;

		cursorRef.current.style.left = `${cursorX - elWidth / 2}px`;
		cursorRef.current.style.top = `${cursorY - elHeight / 2}px`;
	};

	return (
		<Card
			as={Link}
			href={technology.href}
			isExternal
			className="group/technology-card technology-card h-24 min-w-36 cursor-none !transition-all hover:!grayscale-0 group-has-[.technology-card:hover]/technology-showcase:grayscale sm:h-32 sm:min-w-64"
			onMouseMove={handleMouseMove}
			classNames={{ base: "hover:opacity-100" }}
		>
			<span
				ref={cursorRef}
				className="absolute z-10 hidden text-nowrap text-4xl font-black text-white mix-blend-difference group-hover/technology-card:sm:block"
			>
				{technology.title}
			</span>

			<CardBody className="overflow-hidden py-6 sm:py-8">
				{"dark" in technology.logo ? (
					<>
						<TechnologyLogo
							logo={technology.logo.light}
							alt={technology.title}
							theme="light"
						/>
						<TechnologyLogo
							logo={technology.logo.dark}
							alt={technology.title}
							theme="dark"
						/>
					</>
				) : (
					<TechnologyLogo logo={technology.logo} alt={technology.title} />
				)}
			</CardBody>
		</Card>
	);
};
