import { Technology } from "./TechnologyCard";
import { logoClassName } from "./classes";
import AWSLogo from "./assets/aws-logo.svg";
import PayloadLogo from "./assets/payload-logo.svg";
import JavascriptLogo from "./assets/javascript-logo.svg";
import TypescriptLogo from "./assets/typescript-logo.svg";
import TailwindcssLogo from "./assets/tailwindcss-logo.svg";
import FramerMotionLogo from "./assets/framer-motion-logo.svg";
import DockerLogo from "./assets/docker-logo.svg";
import PrismaLogo from "./assets/prisma-logo.svg";
import SentryLogo from "./assets/sentry-logo.svg";
import StripeLogo from "./assets/stripe-logo.svg";
import GithubLogo from "./assets/github-logo.svg";
import GithubActionsLogo from "./assets/github-actions-logo.svg";
import VscodeLogo from "./assets/vscode-logo.svg";
import FigmaLogo from "./assets/figma-logo.svg";
import SlackLogo from "./assets/slack-logo.svg";
import NextUiLogo from "./assets/next-ui-logo.svg";
import WarpLogo from "./assets/warp-logo.svg";
import HTML5Logo from "./assets/html5-logo.svg";
import CSS3Logo from "./assets/css3-logo.svg";
import RailwayLogo from "./assets/railway-logo.svg";
import ViteLogo from "./assets/vite-logo.svg";
import InsomniaLogo from "./assets/insomnia-logo.svg";
import NextJsLogo from "./assets/nextjs-logo.svg";
import NodeJsLogo from "./assets/nodejs-logo.svg";
import OpenAiLogo from "./assets/openai-logo.svg";
import PostgresqlLogo from "./assets/postgresql-logo.svg";
import ReactLogo from "./assets/react-logo.svg";
import ResendLogo from "./assets/resend-logo.svg";
import TurboLogo from "./assets/turbo-logo.svg";
import VercelLogo from "./assets/vercel-logo.svg";
import DBeaverLogo from "./assets/dbeaver-logo.svg";
import ExpressLogo from "./assets/express-logo.svg";
import GitLogo from "./assets/git-logo.svg";
import GoogleCloudLogo from "./assets/google-cloud-logo.svg";
import HomebrewLogo from "./assets/homebrew-logo.svg";
import OpenAPILogo from "./assets/openapi-logo.svg";
import FacebookLogo from "./assets/facebook-logo.svg";
import NPMLogo from "./assets/npm-logo.svg";
import PrometheusLogo from "./assets/prometheus-logo.svg";
import RedisLogo from "./assets/redis-logo.svg";
import SocketioLogo from "./assets/socketio-logo.svg";
import TerraformLogo from "./assets/terraform-logo.svg";
import EslintLogo from "./assets/eslint-logo.svg";
import JestLogo from "./assets/jest-logo.svg";
import NextStudioLogo from "@/assets/logo.svg";

// TO BE ADDED: Datadog, Tanstack, ReactNative, IOS, Android
export const TECHNOLOGIES: Technology[] = [
	{
		title: "Next Studio ;)",
		href: "https://www.next.studio/",
		logo: <NextStudioLogo color="primary" />,
	},
	{
		title: "Jest",
		href: "https://jestjs.io/",
		logo: JestLogo,
	},
	{
		title: "ESLint",
		href: "https://eslint.org/",
		logo: EslintLogo,
	},
	{
		title: "Terraform",
		href: "https://www.terraform.io/",
		logo: TerraformLogo,
	},
	{
		title: "Socket.io",
		href: "https://socket.io/",
		logo: (
			<SocketioLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:*:fill-white"],
				})}
			/>
		),
	},
	{
		title: "Redis",
		href: "https://redis.io/",
		logo: RedisLogo,
	},
	{
		title: "Prometheus",
		href: "https://prometheus.io/",
		logo: PrometheusLogo,
	},
	{
		title: "NPM",
		href: "https://www.npmjs.com/",
		logo: NPMLogo,
	},
	{
		title: "Facebook",
		href: "https://www.facebook.com/",
		logo: FacebookLogo,
	},
	{
		title: "OpenAPI",
		href: "https://www.openapis.org/",
		logo: OpenAPILogo,
	},
	{
		title: "Homebrew",
		href: "https://brew.sh/",
		logo: HomebrewLogo,
	},
	{
		title: "Google Cloud",
		href: "https://cloud.google.com/",
		logo: GoogleCloudLogo,
	},
	{
		title: "Git",
		href: "https://git-scm.com/",
		logo: GitLogo,
	},
	{
		title: "Express",
		href: "https://expressjs.com/",
		logo: (
			<ExpressLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:fill-white"],
				})}
			/>
		),
	},
	{ title: "DBeaver", href: "https://dbeaver.io/", logo: DBeaverLogo },
	{ title: "React", href: "https://react.dev/", logo: ReactLogo },
	{
		title: "Next.js",
		href: "https://nextjs.dev/",
		logo: (
			<NextJsLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:fill-white"],
				})}
			/>
		),
	},
	{
		title: "Vercel",
		href: "https://vercel.com/",
		logo: (
			<VercelLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:*:fill-white"],
				})}
			/>
		),
	},
	{
		title: "Turbo",
		href: "https://turbo.build/",
		logo: TurboLogo,
	},
	{
		title: "Amazon Web Services",
		href: "https://aws.amazon.com/",
		logo: AWSLogo,
	},
	{
		title: "Resend",
		href: "https://resend.com/",
		logo: (
			<ResendLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:*:fill-white"],
				})}
			/>
		),
	},
	{ title: "Node.js", href: "https://nodejs.org/", logo: NodeJsLogo },
	{
		title: "PostgreSQL",
		href: "https://www.postgresql.org/",
		logo: PostgresqlLogo,
	},
	{
		title: "OpenAI",
		href: "https://openai.com/",
		logo: (
			<OpenAiLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:fill-white"],
				})}
			/>
		),
	},
	{
		title: "Payload",
		href: "https://payloadcms.com/",
		logo: (
			<PayloadLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:fill-white"],
				})}
			/>
		),
	},
	{
		title: "JavaScript",
		href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
		logo: JavascriptLogo,
	},
	{
		title: "TypeScript",
		href: "https://www.typescriptlang.org/",
		logo: TypescriptLogo,
	},
	{
		title: "Tailwind CSS",
		href: "https://tailwindcss.com/",
		logo: TailwindcssLogo,
	},
	{
		title: "Framer Motion",
		href: "https://www.framer.com/motion/",
		logo: (
			<FramerMotionLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:fill-white"],
				})}
			/>
		),
	},
	{
		title: "Docker",
		href: "https://www.docker.com/",
		logo: DockerLogo,
	},
	{
		title: "Prisma",
		href: "https://www.prisma.io/",
		logo: (
			<PrismaLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:*:fill-white"],
				})}
			/>
		),
	},
	{
		title: "Sentry",
		href: "https://sentry.io/",
		logo: (
			<SentryLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:*:fill-white"],
				})}
			/>
		),
	},
	{
		title: "Stripe",
		href: "https://stripe.com/",
		logo: StripeLogo,
	},
	{
		title: "Github",
		href: "https://github.com/",
		logo: (
			<GithubLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:*:fill-white"],
				})}
			/>
		),
	},
	{
		title: "Github Actions",
		href: "https://github.com/features/actions",
		logo: GithubActionsLogo,
	},
	{
		title: "Visual Studio Code",
		href: "https://code.visualstudio.com/",
		logo: VscodeLogo,
	},
	{
		title: "Figma",
		href: "https://www.figma.com/",
		logo: FigmaLogo,
	},
	{
		title: "Slack",
		href: "https://slack.com/",
		logo: SlackLogo,
	},
	{
		title: "Next UI",
		href: "https://nextui.org/",
		logo: (
			<NextUiLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:fill-white"],
				})}
			/>
		),
	},
	{
		title: "Warp",
		href: "https://warp.dev/",
		logo: WarpLogo,
	},
	{
		title: "HTML",
		href: "https://developer.mozilla.org/en-US/docs/Web/HTML",
		logo: HTML5Logo,
	},
	{
		title: "CSS",
		href: "https://developer.mozilla.org/en-US/docs/Web/CSS",
		logo: CSS3Logo,
	},
	{
		title: "Railway",
		href: "https://railway.app/",
		logo: (
			<RailwayLogo
				className={logoClassName({
					type: "svg",
					className: ["dark:fill-white"],
				})}
			/>
		),
	},
	{
		title: "Vite",
		href: "https://vitejs.dev/",
		logo: ViteLogo,
	},
	{
		title: "Insomnia",
		href: "https://insomnia.rest/",
		logo: InsomniaLogo,
	},
];
