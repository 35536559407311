import { Input, InputProps } from "@nextui-org/input";
import { FC } from "react";
import { UseControllerProps, useController } from "react-hook-form";

type Props = {
	controllerProps: UseControllerProps;
} & InputProps;

export const ControlledInput: FC<Props> = ({ controllerProps, ...props }) => {
	const { field, fieldState } = useController({ ...controllerProps });

	const errorMessage = fieldState.error?.message?.toString();

	return (
		<Input
			isInvalid={Boolean(errorMessage)}
			errorMessage={errorMessage}
			{...field}
			{...props}
		/>
	);
};
