"use client";

import { ControlledInput } from "@/components/inputs/ControlledInput";
import { ControlledTextarea } from "@/components/inputs/ControlledTextarea";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "@nextui-org/button";
import { Phone } from "iconoir-react";
import { validateZodSchema } from "@/utils/validateZodSchema";
import { z } from "zod";
import { sendContactFormSubmissionEmail } from "@/actions/sendEmail";
import { toast } from "sonner";

const emailSchema = z
	.string({ message: "Email is required" })
	.min(1, { message: "Email is required" })
	.email({ message: "Invalid email" });

type FormFields = {
	name: string;
	email: string;
	message: string;
};

type Props = {};

export const ContactForm: FC<Props> = () => {
	const formContext = useForm<FormFields>({
		defaultValues: { name: "", email: "", message: "" },
	});

	const handleSubmit = formContext.handleSubmit(async (data) => {
		await sendContactFormSubmissionEmail(data);

		toast.success("Message sent successfully");
		formContext.reset();
	});

	return (
		<FormProvider {...formContext}>
			<form
				noValidate
				onSubmit={handleSubmit}
				className="flex flex-col gap-y-6"
			>
				<div className="flex flex-col gap-y-3">
					<ControlledInput
						controllerProps={{
							name: "name",
							rules: { required: "Name is required" },
						}}
						placeholder="Name"
					/>

					<ControlledInput
						controllerProps={{
							name: "email",
							rules: {
								validate: validateZodSchema(emailSchema, {
									maxIssuesInMessage: 1,
								}),
							},
						}}
						placeholder="Email"
					/>

					<ControlledTextarea
						controllerProps={{
							name: "message",
							rules: { required: "Message is required" },
						}}
						placeholder="Enter your message here..."
					/>
				</div>

				<Button
					type="submit"
					color="secondary"
					variant="shadow"
					className="font-semibold"
					isLoading={formContext.formState.isSubmitting}
				>
					Send message
				</Button>
			</form>
		</FormProvider>
	);
};
