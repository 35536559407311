import { Textarea, TextAreaProps } from "@nextui-org/input";
import { FC } from "react";
import { UseControllerProps, useController } from "react-hook-form";

type Props = {
	controllerProps: UseControllerProps;
} & TextAreaProps;

export const ControlledTextarea: FC<Props> = ({
	controllerProps,
	...props
}) => {
	const { field, fieldState } = useController(controllerProps);

	const errorMessage = fieldState.error?.message?.toString();

	return (
		<Textarea
			isInvalid={Boolean(errorMessage)}
			errorMessage={errorMessage}
			{...field}
			{...props}
		/>
	);
};
