"use client";

import { now, getLocalTimeZone, DateFormatter } from "@internationalized/date";
import { Chip } from "@nextui-org/chip";
import { FC, useState, useEffect } from "react";

const formatter = new DateFormatter("en", { month: "long" });

type Props = {};

export const AvailabilityChip: FC<Props> = ({}) => {
	const [currentDate, setCurrentDate] = useState<Date>();

	useEffect(() => {
		setCurrentDate(now(getLocalTimeZone()).toDate());
	}, []);

	return (
		<Chip
			size="md"
			className="text-xs"
			variant="dot"
			color={currentDate ? "success" : "default"}
		>
			{currentDate
				? `Availability remaining for ${formatter.format(currentDate)}`
				: "Loading..."}
		</Chip>
	);
};
