"use client";

import { motion, useAnimate } from "framer-motion";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Technology, TechnologyCard } from "./TechnologyCard";
import { cx } from "class-variance-authority";

type Props = {
	technologies: Technology[];
	direction: "left" | "right";
};

export const TechnologyCardRow: FC<Props> = ({ technologies, direction }) => {
	const [scope, animate] = useAnimate<HTMLDivElement>();
	const parentRef = useRef<HTMLDivElement>(null);
	const [controls, setControls] = useState<ReturnType<typeof animate>>();

	useEffect(() => {
		if (scope.current && !controls && parentRef.current) {
			const scrollWidth = scope.current.scrollWidth;
			const targetX = (scrollWidth / 2) * (direction === "left" ? 1 : -1);

			const controls = animate(
				scope.current,
				{
					x: targetX,
				},
				{
					ease: "linear",
					duration: technologies.length * 3, // Adjust duration based on the number of  technologies
					repeat: Infinity,
					repeatType: "loop",
				},
			);

			setControls(controls);
		}
	}, [animate, controls, direction, scope, technologies.length]);

	const doubledTechnologies = useMemo(
		() => [...technologies, ...technologies],
		[technologies],
	);

	return (
		<div
			ref={parentRef}
			className="relative flex w-full"
			onMouseEnter={() => controls?.pause()}
			onMouseLeave={() => controls?.play()}
		>
			<motion.div
				// TODO: Rework this so it doesn't rely on using the direction prop
				dir={direction === "left" ? "rtl" : "ltr"}
				ref={scope}
				className={cx([
					"flex",
					"gap-x-6",
					"px-6",
					"w-full",
					"flex-row-reverse",
					"justify-end",
					"direction",
				])}
			>
				{doubledTechnologies.map((technology, i) => (
					<TechnologyCard key={i} technology={technology} />
				))}
			</motion.div>
		</div>
	);
};
