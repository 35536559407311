import { StaticImageData } from "next/image";
import { FC, ReactElement } from "react";
import NextImage from "next/image";
import { logoClassName } from "./classes";
import { SVGComponent } from "@/types/types";

export type Logo = StaticImageData | SVGComponent | ReactElement;

type Props = {
	logo: Logo;
	alt: string;
	theme?: "light" | "dark";
};

export const TechnologyLogo: FC<Props> = ({ logo, alt, theme }) => {
	if ("src" in logo) {
		return (
			<NextImage
				src={logo}
				alt={alt}
				className={logoClassName({ type: "image", theme })}
			/>
		);
	} else if (typeof logo === "function") {
		const LogoComponent = logo;

		return <LogoComponent className={logoClassName({ type: "svg", theme })} />;
	} else {
		return logo;
	}
};
