"use client";

import { FC } from "react";
import { Technology } from "./TechnologyCard";
import { TechnologyCardRow } from "./TechnologyCardRow";
import { TECHNOLOGIES } from "./consants";

const NUMBER_OF_ROWS = 3;

if (TECHNOLOGIES.length % NUMBER_OF_ROWS !== 0) {
	throw new Error(
		`Number of technologies (${TECHNOLOGIES.length}) must be divisible by the number of rows (${NUMBER_OF_ROWS})`,
	);
}

type Props = {};

export const TechnologyShowcase: FC<Props> = () => {
	const rows: Technology[][] = Array.from({ length: NUMBER_OF_ROWS }, () => []);

	for (let i = 0; i < TECHNOLOGIES.length; i++) {
		rows[i % NUMBER_OF_ROWS].push(TECHNOLOGIES[i]);
	}

	return (
		<div className="group/technology-showcase relative flex w-full flex-col gap-y-6 overflow-x-hidden py-6">
			{/* Fade overlays */}
			<div className="pointer-events-none absolute left-0 top-0 z-20 h-full w-1/12 bg-gradient-to-r from-background to-transparent" />
			<div className="pointer-events-none absolute right-0 top-0 z-20 h-full w-1/12 bg-gradient-to-l from-background to-transparent" />

			{rows.map((row, i) => (
				<TechnologyCardRow
					key={i}
					technologies={row}
					direction={i % 2 ? "right" : "left"}
				/>
			))}
		</div>
	);
};
